html,
body,
#root {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;

  min-width: 500px;

  width: 100%;

  overflow: hidden;
}

.clock-overlay {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;

  img {
    height: 100%;
    opacity: 0.9;
  }
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 1.5rem;
  padding-right: 0;

  height: 100%;

  .modal-header {
    align-items: center;

    h1 {
      font-family: "Open Sans", sans-serif;
      font-size: 22px;
      font-weight: 400;
      margin: 0;
      color: #f16534;

      .logo-icon {
        width: 30px;
        margin: 0 10px 0 0;
      }
    }
  }

  .modal-container {
    position: relative;

    a {
      padding: 5px 0;
      color: #41537b;
      display: block;

      &:hover {
        color: #f16534;
      }

      &:link,
      &:visited {
        outline: 0;
        text-decoration: none;
      }
    }
  }
}
